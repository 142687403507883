import { useQuery } from '@vue/apollo-composable';
import { computed, onMounted } from 'vue';
import { useNotification } from '@/modules/core';

import { ORDER_AGGREGATION_BY_SUPPLIER_QUERY } from './graphql';

export function useOrderAggregationBySupplier(businessId, supplierId, dateRange, monthlyAverage) {
  const { error } = useNotification();

  const variables = computed(() => ({
    businessId: businessId.value,
    supplierId,
    fromDate: !monthlyAverage?.value ? dateRange.value?.fromDate : null,
    toDate: !monthlyAverage?.value ? dateRange.value?.toDate : null,
    monthlyAverage: monthlyAverage?.value,
  }));

  const { result, loading, onError, refetch } = useQuery(ORDER_AGGREGATION_BY_SUPPLIER_QUERY, variables, () => ({
    enabled: !!dateRange.value,
    debounce: 300,
  }));

  const groupBySupplier = computed(() =>
    result.value
      ? result.value.orderAggregation?.groupBySupplier?.nodes.map((node) => ({
          ...node,
          firstOrder: { ...node.firstOrder, date: new Date(node.firstOrder.date) },
          lastOrder: { ...node.lastOrder, date: new Date(node.lastOrder.date) },
        })) ?? []
      : []
  );

  onMounted(() => {
    refetch(); // fix the first loading of the page, the cache throws an error
  });

  onError((err) => {
    console.error('useOrderAggregationBySupplier', err);
    error();
  });

  return {
    groupBySupplier,
    loading,
    refetch,
  };
}
